import {APP_NAME_IIMJOBS, APP, BOTUSERAGENTS} from '../constants';
import {mergeDeep} from '@jobseeker/common-core/lib/utils';
import {locations} from '@jobseeker/lotus/lib/components/JobSearchBar/data';

import type {MetaElement, MetaType} from '@configs';
import type {BaseContext} from 'next/dist/shared/lib/utils';

/**
 * This function consolidates the app configuration based on app name.
 * @param meta meta config that needs to filtered out
 * @param path current page name, which to be loaded
 * @param isCommon boolean type signifies whether need the common
 *                 configuration or not i.e header or footer
 * @returns the filtered configuration object
 */
const getConfigByApp = (
  meta: MetaType<MetaElement>,
  path: string,
  isCommon: boolean,
  page?: string
) => {
  const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || APP_NAME_IIMJOBS;

  const metaInfo = meta[APP_NAME];

  const metaData = isCommon
    ? Object.freeze(
        mergeDeep(
          {page, path},
          metaInfo[APP]['common'],
          metaInfo[APP]['subscription'],
          metaInfo[APP]['featureFlags'],
          metaInfo[APP][path] || {}
        )
      )
    : {
        ...metaInfo,
        app: metaInfo[APP][path],
        page,
        path,
        featureFlags: metaInfo[APP]['featureFlags'],
      };

  return metaData;
};

export const slugToTitle = (slug: string): string => {
  return slug.replace('-jobs', '').replaceAll('-', ' ');
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const removeEmpty = (obj: {[x: string]: any}) => {
  const newObj: {[x: string]: any} = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const removedUndefinedFromArrayObject = (arrayObj: Array<object>) => {
  if (Array.isArray(arrayObj)) {
    return arrayObj.map((obj: {[x: string]: any}) => {
      return removeEmpty(obj);
    });
  }
};

const getIdBySlug = (
  page: string,
  slug: string,
  tabList: Array<{dataId: string; label: string; tag: string; menu: Tabs[]}>,
  swap = false
): {id: string; title: string; catId?: number} => {
  const filteredSlug = slugToTitle(slug);

  let filtered = {
    id: '',
    title: '',
    catId: -1,
  };

  if (page === 'category') {
    let filteredTab;
    if (swap) {
      filteredTab = tabList.filter(tab => {
        const {dataId} = tab || '';
        return dataId === filteredSlug;
      });
    } else {
      filteredTab = tabList.filter(tab => {
        const {tag} = tab || '';
        return tag === filteredSlug;
      });
    }

    if (filteredTab && filteredTab.length > 0) {
      filtered = {
        id: filteredTab[0].dataId,
        title: filteredTab[0].label.replace('Jobs', ''),
        catId: -1,
      };
    }
  } else if (page === 'tag') {
    for (const tab of tabList) {
      const {menu} = tab || {};

      const filteredTab = menu.filter(item => {
        const {tag} = item || '';
        return tag === filteredSlug;
      });
      if (filteredTab && filteredTab.length > 0) {
        filtered = {
          id: filteredTab[0].dataId,
          title: filteredTab[0].label,
          catId: parseInt(tab?.dataId),
        };
        break;
      }
    }
  }

  return filtered;
};

const getLocationIds = (locationString: string) => {
  if (!locationString) {
    return '';
  }
  const locationLabels = locationString?.split('_');

  const locationIds: Array<number> = [];

  locationLabels.forEach(location => {
    locations.forEach(locObj => {
      if (location.replace('-', ' ') === locObj?.label) {
        locationIds.push(locObj?.value);
      }
    });
  });

  return locationIds?.join(',');
};

const getSelectedLocationIds = (locationString: string) => {
  if (!locationString) {
    return '';
  }

  let location;
  locations.forEach(locObj => {
    if (locationString.replace('-', ' ') === locObj?.label) {
      if (locObj?.selected.length > 0) {
        location = locObj.selected;
      } else {
        location = locObj?.value;
      }
    }
  });

  return location;
};

const getCategoryLabel = (tabsList: any, categoryId: number) => {
  const tags = [];
  for (let i = 0; i < tabsList.length; i++) {
    if (tabsList[i]?.dataId == categoryId) {
      return tabsList[i];
    }
    tags.push(...tabsList[i].menu);
  }

  for (let j = 0; j < tags.length; j++) {
    if (tags[j].dataId == categoryId) {
      return tags[j];
    }
  }
};

type Tabs = {
  dataId: string;
  label: string;
  tag: string;
};

export const setQueryParams = (queryParams: any) => {
  const url = new URL(window?.location as unknown as string);
  const searchParams = new URLSearchParams(window.location.search);

  for (const key in queryParams) {
    // searchParams.set(key, queryParams[key].toString());
    if (queryParams[key]) {
      url.searchParams.set(key, queryParams[key]?.toString());
    } else {
      url.searchParams.delete(key);
    }
    if (
      !parseInt(searchParams.get('minexp') || '') &&
      !queryParams?.['minexp']
    ) {
      url.searchParams.delete('minexp');
      url.searchParams.delete('maxexp');
    }
  }
  window.history.replaceState(null, '', url.toString());
};

export const isUserAgentForBot = (context: BaseContext) => {
  const userAgent = context.req?.headers['user-agent'];
  const botRegx = new RegExp(BOTUSERAGENTS.join('|'), 'i');

  return botRegx.test(userAgent);
};

export const cleanObj = (obj: {[key: string]: string | number}) => {
  for (const propName in obj) {
    if (!obj[propName] || obj[propName] === '0') {
      delete obj[propName];
    }
  }
  return obj;
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) {
    return '';
  }
  return string[0].toUpperCase() + string.slice(1);
};

export const truncateString = (str: string, index: number) => {
  if (str.length <= index) {
    return str;
  }

  return str.slice(0, index) + '...';
};

export const getDeviceOs = (userAgent: string): string => {
  const isMobile =
    /(iPad|iPhone|Android|webOS|iPod|BlackBerry|IEMobile|Opera Mini|Mobile)/i.test(
      userAgent
    ) || false;

  if (isMobile) {
    if (/android/i.test(userAgent)) {
      return 'android';
    }

    if (/iphone|ipad|ipod|iPhone|ios/.test(userAgent)) {
      return 'ios';
    }
  }

  return 'web';
};

export const utilServerSideDeviceDetection = (context: BaseContext) => {
  const isServer = !!context.req;
  const userAgent: string = isServer
    ? context.req.headers['user-agent']
    : navigator.userAgent;
  const isLine = /\bLine\//i.test(userAgent) || false;
  const isMobile = /(iPad|iPhone|Android|Mobile)/i.test(userAgent) || false;
  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv|.0.0.0)',
  ];

  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  const isInApp = Boolean(userAgent?.match(regex));
  const deviceType = getDeviceOs(userAgent);

  return {
    deviceType,
    isMobile,
    isLine,
    isInApp,
    userAgent,
  };
};

const getMonth = (month: number) => {
  if (month > 12 || month < 1) {
    return '';
  }
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][month - 1];
};

export {
  getConfigByApp,
  getIdBySlug,
  getLocationIds,
  getSelectedLocationIds,
  getCategoryLabel,
  getMonth,
};
